import { TabMenu } from "primereact/tabmenu";
import React, { useState } from "react";
import { Route, useHistory } from "react-router-dom";
import { MdOutlineRedeem } from "react-icons/md";

import Wholesalebanks from "./CBHome/CBIncomingRequest/Wholesalebanks";
import IssueRequestCB from "./CBHome/CBIncomingRequest/IssueRequestCB";
import MARequestCB from "./CBHome/CBIncomingRequest/MARequestCB";
import RedemptionRequest from "./CBHome/CBIncomingRequest/RedemptionRequest";

const CBIncomingRequest = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const history = useHistory();
  const wizardItems = [
    {
      label: "ACCESS CONTROL ",
      icon: "pi text-xl text-blue-500 pi-fw pi-cog",
    },

    {
      label: "CBDC ISSUANCE",
      icon: "pi text-xl text-blue-500 pi-fw pi-arrow-up",
    },
    {
      label: "REDEMPTION REQUESTS",
      icon: "pi text-xl text-blue-500 pi-fw pi-gift",
    },
    /*
    {
      label: "MEMBER ACCESS REQUEST",
      icon: "pi text-xl pi-fw pi-user",
      command: () =>
        history.push("/central-bank/incoming-requests/member-access-request"),
    },
    */
  ];

  const DisplayItem = () => {
    if (activeIndex === 0) {
      return <Wholesalebanks />;
    } else if (activeIndex === 1) {
      return <IssueRequestCB />;
    } else if (activeIndex === 2) {
      return <RedemptionRequest />;
    }
  };

  return (
    <div className="grid p-fluid p-5 d-flex justify-content-center">
      <div className="row-12  col-12 lg:col-9 p-5">
        <div className="card card-w-title border-1 border-100 h-full">
          {/* <h5 className="text-5xl p-5">Centralbank controls </h5> */}
          <TabMenu
            model={wizardItems}
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
            readOnly={false}
            style={{ fontSize: "1.3rem" }}
          />
          {DisplayItem()}
        </div>
      </div>
    </div>
  );
};

export default CBIncomingRequest;
