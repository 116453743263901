import React, { useState, useEffect } from 'react';

import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { IssuanceServiceWBOB } from './issuanceServiceWBOB';

import { Column } from 'primereact/column';
import * as _ from 'lodash';

function Pvpsellproposals({
	user,
	buy,
	sell,
	firstcurrency,
	secondcurrency,
	chosenpair,
	setOrderplacedbuy,
	setOrderplacedsell,
}) {
	const [modifiedsell, setModifiedsell] = useState([]);
	const [consideredbuy, setConsideredbuy] = useState([]);

	useEffect(() => {
		var tmpdata = sell.filter((xx) => {
			if (user.subcentralaccountnumber === xx.specificaccountnumber)
				return true;
			if (user.subcentralaccountnumber === xx.sellaccountnumber) return true;
		});
		setModifiedsell(tmpdata);
	}, [sell, buy]);

	useEffect(() => {
		var tmpdata = buy.filter((xx) => {
			if (user.subcentralaccountnumber === xx.buyaccountnumber) return true;
		});
		// consider buys only from this user
		setConsideredbuy(tmpdata);
	}, [buy]);

	const issuanceServiceWBOB = new IssuanceServiceWBOB();

	const anyprepared = (rowdata) => {
		var anyfound = consideredbuy.filter((xx) => {
			if (
				xx.buyprice === rowdata.sellprice &&
				xx.buyamount === rowdata.sellamount
			)
				return true;
		});
		console.log('Sell PVP Transactions: ', rowdata);
		console.log('Any prepared: ', anyfound);
		if (anyfound.length > 0) return true;
		else return false;
	};

	const acceptatomsellorder = async (rowData) => {
		var chosenpair = rowData.pairname;

		var price = rowData.sellprice;
		var amount = rowData.sellamount;
		var prepared = rowData.prepared;

		var ret = await issuanceServiceWBOB.acceptatomsellorder(
			chosenpair,
			rowData,
			firstcurrency,
			secondcurrency,
			price,
			amount
		);

		console.log(ret);
		if (ret && ret.code === -1) {
			ret = await issuanceServiceWBOB.acceptatomsellorder(
				chosenpair,
				rowData,
				firstcurrency,
				secondcurrency,
				price,
				amount
			);
		}

		setOrderplacedsell(true);
		/*
        if(ret && ret.txid) {
           setOrderplacedsell(true);
        }
          else {
          alert ("Order failed");
          }
*/

		if (_.has(ret, 'code')) {
			if (ret.code === 0) {
				alert('Accept success');
			} else {
				alert('Accept failed');
				return;
			}
		}

		/*       if(_.has(ret, 'error')) {
                alert('Accept failed');
                 return;
        }

*/
	};

	const rejectatomsellorder = async (rowData) => {
		var chosenpair = rowData.pairname;

		var price = rowData.sellprice;
		var amount = rowData.sellamount;
		var prepared = rowData.prepared;

		var ret = await issuanceServiceWBOB.rejectatomsellorder(
			chosenpair,
			rowData,
			firstcurrency,
			secondcurrency,
			price,
			amount
		);

		console.log(ret);
		if (ret && ret.code === -1) {
			ret = await issuanceServiceWBOB.rejectatomsellorder(
				chosenpair,
				rowData,
				firstcurrency,
				secondcurrency,
				price,
				amount
			);
		}

		setOrderplacedsell(true);
		/*
        if(ret && ret.txid) {
           setOrderplacedsell(true);
        }
          else {
          alert ("Order failed");
          }
*/

		if (_.has(ret, 'code')) {
			if (ret.code === 0) {
				alert('Reject success');
			} else {
				alert('Reject failed');
				return;
			}
		}

		if (_.has(ret, 'error')) {
			/*
                 *
                "side": "atomicbuyside",
                "needed": 190,
                "neededsymbol": "Digital_INR",
                "error": "failed to prepare"
                 * */

			alert('Reject failed');
			return;
		}

		if (_.has(ret, 'transactionid')) {
			alert('Reject success');
		}
	};
	const cancelthis = async (rowData) => {
		var chosenpair = rowData.pairname;

		var price = rowData.sellprice;
		var amount = rowData.sellamount;
		var prepared = rowData.prepared;

		var ret = await issuanceServiceWBOB.canceloneprepare(
			chosenpair,
			rowData,
			firstcurrency,
			secondcurrency,
			price,
			amount
		);

		console.log(ret);
		if (ret && ret.code === -1) {
			ret = await issuanceServiceWBOB.canceloneprepare(
				chosenpair,
				rowData,
				firstcurrency,
				secondcurrency,
				price,
				amount
			);
		}
		setOrderplacedsell(true);

		if (_.has(ret, 'code')) {
			if (ret.code === 0) {
				alert('Cancel success');
			} else {
				alert('Cancel failed');
				return;
			}
		}

		if (_.has(ret, 'error')) {
			/*
                 *
                "side": "atomicbuyside",
                "needed": 190,
                "neededsymbol": "Digital_INR",
                "error": "failed to prepare"
                 * */

			alert('Cancel failed');
			return;
		}

		if (_.has(ret, 'txid')) {
			alert('Cancel success');
		}
	};

	const spriceBodyTemplate = (rowData) => {
		return <span className="text-green-400"> {rowData.sellprice} </span>;
	};

	const pairnameBodyTemplate = (rowData) => {
		return rowData.pairname;
	};

	const buyselltemplate = (rowData) => {
		return rowData.side === 'atomicbuyside' ? 'BUY' : 'SELL';
	};

	const executetemplate = (rowData) => {
		if (user.subcentralaccountnumber === rowData.sellaccountnumber) {
			return (
				<div className="flex align-items-center gap-2">
					<Button
						label="Cancel"
						icon="pi "
						onClick={() => cancelthis(rowData)}
						className="text-l p-button-warning"
					/>
				</div>
			);
		} else if (user.subcentralaccountnumber === rowData.specificaccountnumber) {
			if (anyprepared(rowData) === true) {
				return (
					<div className="flex align-items-center gap-2">
						<Button
							label="Accept"
							icon="pi "
							onClick={() => acceptatomsellorder(rowData)}
							className="text-l p-button-success"
						/>
						<Button
							label="Reject"
							icon="pi "
							onClick={() => rejectatomsellorder(rowData)}
							className="text-l p-button-danger"
						/>
					</div>
				);
			} else {
				return (
					<div className="flex align-items-center gap-2">
						<Button
							label="Prepare"
							icon="pi "
							onClick={() => preparematchforatomsellorder(rowData)}
							className="text-l p-button-secondary"
						/>
						<Button
							label="Reject"
							icon="pi "
							onClick={() => rejectatomsellorder(rowData)}
							className="text-l p-button-danger"
						/>
					</div>
				);
			}
		}
	};
	const preparematchforatomsellorder = async (rowData) => {
		//    /atomicexchange/createmakeorder
		var price = rowData.sellprice;
		var volume = rowData.sellamount;

		var ret = await issuanceServiceWBOB.placeatombuyorder(
			'',
			chosenpair,
			firstcurrency,
			secondcurrency,
			price,
			volume
		);
		console.log(ret);
		if (ret && ret.code === -1) {
			ret = await issuanceServiceWBOB.placeatombuyorder(
				'',
				chosenpair,
				firstcurrency,
				secondcurrency,
				price,
				volume
			);
		}
		setOrderplacedbuy(true);

		if (ret && ret.code === -2) {
			alert('System error, contact support, error code ' + ret.track);
			return;
		}
		if (ret && ret.code === -1) {
			alert(
				'Order failed : needed ' +
					ret.needed +
					' ' +
					ret.neededsymbol +
					' found ' +
					ret.found +
					' error code ' +
					ret.track
			);
			return;
		}

		if (_.has(ret, 'error')) {
			/*
                 *
                "side": "atomicbuyside",
                "needed": 190,
                "neededsymbol": "Digital_INR",
                "error": "failed to prepare"
                 * */

			if (Number(ret.needed) < Number(ret.found)) {
				alert(
					'Merging in progress : needed ' +
						ret.needed +
						' ' +
						ret.neededsymbol +
						' found ' +
						ret.found
				);
				alert('Try after some time');
				return;
			} else {
				alert(
					'Prepare failed : needed ' +
						ret.needed +
						' ' +
						ret.neededsymbol +
						' found ' +
						ret.found
				);
				alert('Check balance ');
				return;
			}
		}

		if (_.has(ret, 'transactionid')) {
			alert('Prepare success');
		}
	};

	const samountBodyTemplate = (rowData) => {
		return rowData.sellamount;
	};
	const dateBodyTemplate1 = (rowData) => {
		return (
			<>
				{new Intl.DateTimeFormat('en-US', {
					year: 'numeric',
					month: '2-digit',
					day: '2-digit',
					hour: '2-digit',
					minute: '2-digit',
					second: '2-digit',
				}).format(rowData.createdAt)}
			</>
		);
	};

	const idBodyTemplate = (rowData) => {
		return rowData.buyaccountnumber
			? rowData.buyaccountnumber
			: rowData.sellaccountnumber;
	};

	return (
		<div className="grid table-demo">
			<div className="col-12">
				<div className="card border-1 border-100  ">
					<div className="flex ml-1">
						<DataTable
							value={modifiedsell}
							scrollable
							scrollHeight="350px"
							responsiveLayout="scroll"
							className="text-xl border-none w-full"
						>
							<Column
								field="price"
								header="Pair"
								sortable
								body={pairnameBodyTemplate}
								rows={5}
								className="text-xl border-none w-5 lg:w-7"
							/>

							<Column
								field="price"
								header="Price"
								sortable
								body={spriceBodyTemplate}
								rows={5}
								className="text-xl border-none w-2 md:w-3 lg:w-4"
							/>
							<Column
								field="price"
								header="Volume"
								sortable
								body={samountBodyTemplate}
								rows={5}
								className="text-xl border-none w-2 md:w-3 lg:w-4"
							/>
							<Column
								// field="price"
								header="ID"
								sortable
								body={idBodyTemplate}
								className="text-xl border-none w-5 lg:w-7"
							/>

							<Column
								header="Side"
								body={buyselltemplate}
								className="text-xl border-none w-15rem lg:w-20rem"
							/>
							<Column
								// field="price"
								header="Time"
								sortable
								body={dateBodyTemplate1}
								className="text-xl border-none w-4 lg:w-5"
							/>
							<Column
								header="Execute"
								body={executetemplate}
								className="text-l border-none w-15rem lg:w-20rem"
							/>
						</DataTable>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Pvpsellproposals;
