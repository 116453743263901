import React, { useState, useEffect } from 'react';
import { useToken } from '../../components/App/useToken';
import { BsBank } from 'react-icons/bs';
import { BiLogOut } from 'react-icons/bi';
import { NavLink, Route, useHistory } from 'react-router-dom';
import { Menubar } from 'primereact/menubar';
import { Button } from 'primereact/button';

import { TabMenu } from 'primereact/tabmenu';
import WBOHome from '../../components/WBOneTab/WBOHome';
import WBOCbdcManager from '../../components/WBOneTab/WBOCbdcManager';
import Adminwholesale from '../Admin/Adminwholesale';
import WBOAccessControls from '../../components/WBOneTab/WBOAccessControls';
import WBORequests from '../../components/WBOneTab/WBORequests';
import WBOTreasury from '../../components/WBOneTab/WBOTreasury';

import WBOMoneySwipeTrans from '../../components/WBOneTab/WBOMoneySwipetrans';
import WBOFxTrade from '../../components/WBOneTab/WBOFxTrade';
import WBOOrderBook from '../../components/WBOneTab/WBOOrderBook';
import WBOAtomicOrderBook from '../../components/WBOneTab/WBOAtomicOrderBook';
import PvpAtomic from '../../components/WBOneTab/PvpAtomic';
import { LoginService } from '../../components/devlogin/LoginService';

import { MdWifiProtectedSetup } from 'react-icons/md';
import { MdSwapVerticalCircle } from 'react-icons/md';

const WBankOne = () => {
	const history = useHistory();
	const usetoken = new useToken();
	const [user, setUser] = useState(usetoken.getUser());
	const [rolemessaged, setRolemessaged] = useState(0);
	const [currentuser, setCurrentuser] = useState('');

	const Redirectnonconfigured = () => {
		if (!user || user.marker == '') {
			alert('The system is not configured');
			history.push('/');
		}
	};

	const loginservice = new LoginService();
	var alreadyused = false;

	useEffect(() => {
		setRolemessaged(0);
	}, []); //

	useEffect(() => {
		if (alreadyused == false) {
			gotoproperrole();
			alreadyused = true;
		}
	}, [user.role]); //

	const gotoproperrole = () => {
		if (user.role == 'Central bank') {
			alert('Logged in as Central bank, going to Central bank ');
			history.push('/central-bank');
		}
	};

	const reloadifneeded = (data) => {
		if (data.email != currentuser) {
			window.location.reload(false);
		}
	};

	const getlatestdata = async () => {
		const tokendata = await loginservice.getlatestuser();
		if (tokendata.token) {
			usetoken.saveToken(tokendata);
			//	    reloadifneeded(tokendata.user);
			setCurrentuser(tokendata.user.email);
		}
	};

	const wizardItems = [
		{
			label: 'HOME',
			icon: 'pi pi-fw text-2xl text-yellow-600 pi-th-large',
			command: () => history.push('/wholesale-bank-one'),
		},
		{
			label: 'CBDC ADMIN ',
			icon: 'pi pi-fw text-2xl text-yellow-600  pi-shield',
			command: () => history.push('/wholesale-bank-one/cbdc-admin'),
		},
		{
			label: 'TRADE SETUP',
			icon: (
				<MdWifiProtectedSetup className="pi pi-fw text-3xl text-yellow-600  " />
			),
			command: () => history.push('/wholesale-bank-one/trade-setup'),
		},
		{
			label: 'BI SWAP ',
			icon: (
				<MdSwapVerticalCircle className="pi pi-fw text-3xl text-yellow-600  " />
			),
			command: () => history.push('/wholesale-bank-one/pvp-bi-swap'),
		},

		{
			label: 'CBDC REQUESTS',
			icon: 'pi pi-fw text-2xl text-yellow-600  pi-arrow-circle-down',
			command: () => history.push('/wholesale-bank-one/requests'),
		},
		{
			label: 'TREASURY DASHBOARD',
			icon: 'pi text-2xl text-yellow-600  pi-fw pi-chart-pie',
			command: () => history.push('/wholesale-bank-one/treasury-dashboard'),
		},
	];
	Redirectnonconfigured();

	const logout = (
		<span className="flex align-items-center justify-content-center">
			<BiLogOut className="text-3xl mr-2  text-red-500" />
			LOGOUT
		</span>
	);

	const handleLogout = () => {
		localStorage.clear();
		window.location.href = '/wb-login';
	};

	return (
		<div>
			<Menubar
				start={
					<img
						src={'https://intrasettle.com/static/img/intrasettle_white.svg'}
						alt="logo"
						style={{ width: '12rem' }}
					/>
				}
				end={
					<div className="flex align-items-center justify-content-center">
						<BsBank className="text-lg md:text-3xl mr-2 md:mr-3  text-yellow-600" />
						<span className="text-md sm:text-2xl md:text-3xl text-white mr-2 md:mr-3">
							The {user.organization}
						</span>
						<Button
							label={logout}
							onClick={() => handleLogout()}
							className="col m-3 text-md sm:text-lg md:text-xl p-button-danger"
						/>
					</div>
				}
				className="pt-4 pb-4 layout-topbar"
			/>
			<div className=" col-12  justify-content-around pt-8 px-7 ">
				<TabMenu
					model={wizardItems}
					activeIndex={0}
					// setActiveIndex={(e) => e.index}
					end={<Button label="noifications" icon="pi pi-bell" />}
					style={{ fontSize: '1.3rem' }}
					className="pt-4 pb-1 card text-xl"
				/>
			</div>
			<Route exact path={'/wholesale-bank-one'} component={WBOHome} />
			<Route
				path={'/wholesale-bank-one/cbdc-manager'}
				component={WBOCbdcManager}
			/>
			<Route
				path={'/wholesale-bank-one/cbdc-admin'}
				component={Adminwholesale}
			/>
			<Route path={'/wholesale-bank-one/trade-setup'} component={WBOFxTrade} />
			<Route path={'/wholesale-bank-one/order-book'} component={WBOOrderBook} />
			<Route
				path={'/wholesale-bank-one/atomic-order-book'}
				component={WBOAtomicOrderBook}
			/>
			<Route path={'/wholesale-bank-one/pvp-bi-swap'} component={PvpAtomic} />

			<Route
				path={'/wholesale-bank-one/access-controls'}
				component={WBOAccessControls}
			/>
			<Route path={'/wholesale-bank-one/requests'} component={WBORequests} />
			<Route
				path={'/wholesale-bank-one/treasury-dashboard'}
				component={WBOTreasury}
			/>

			<Route
				path={'/wholesale-bank-one/corda-dashboard'}
				component={WBOMoneySwipeTrans}
			/>
		</div>
	);
};

export default WBankOne;
