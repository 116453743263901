import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { RotatingLines } from 'react-loader-spinner';

// page for select asset step
const SelectAsset = ({ data, setData, entitybalance, setUpdateasset }) => {
	const [loading, setLoading] = useState(true);

	// Simulate API call delay (you should replace this with your actual API call)
	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 6000); // Simulate a 2-second delay
	}, []);

	const handleDropdownChange = (e) => {
		setData({ ...data, asset: e.target.value });
		setUpdateasset(e.target.value);
	};
	// Define the options dynamically based on the loading state
	const dropdownOptions = loading
		? [{ label: 'Loading...', value: null }] // Show "Loading..." while loading
		: entitybalance;

	return (
		<div className="grid p-fluid">
			<div className="col-12 text-center">
				<div className="text-center text-sm">
					<p className="text-center text-3xl">Select an asset to issue</p>
					{loading ? (
						// Show a loading message or spinner while data is being fetched
						// <p>Loading...</p>
						<RotatingLines
							strokeColor="grey"
							strokeWidth="5"
							animationDuration="0.75"
							width="40"
							visible={true}
						/>
					) : (
						// Show the dropdown when data is fetched
						<Dropdown
							value={data.asset}
							onChange={handleDropdownChange}
							options={dropdownOptions}
							optionLabel="label"
							placeholder="Select"
							className="p-2 font-semibold bg-gray-100"
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default SelectAsset;
