import React, { useEffect, useState } from "react";
//import { ConsoleLogger } from '../Log/Logger';
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Route, useHistory } from "react-router-dom";

import { useToken } from "../App/useToken";
import { LoginService } from "../devlogin/LoginService";

import { IssuanceService } from "../CBtabmenu/CBHome/IssuanceService";

export default function Wbhealthcheck({ setToken }) {
  const Entityinfo = {
    email: "",
    username: "",
    urlname: "",
    role: "",
    organization: "",
    accountid: "",
    entityaccountnumber: "",
    centralaccountnumber: "",
    entityemail: "",
    entityname: "",
    systemid: "",
    issuerid: "",
    funderid: "",
    entityid: "",
    marker: "",
  };

  const Sendinfo = {
    systemid: "",
    toaccountnumber: "",
    symbol: "",
    amount: "",
  };
  const availableformint = () => {};
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [available, setAvailable] = useState(0);

  const [sendinfo, setSendinfo] = useState(Sendinfo);
  const [entityinfo, setEntityinfo] = useState(Entityinfo);

  const [error, setError] = useState("");
  const [symbol, setSymbol] = useState({});
  const [amount, setAmount] = useState("");

  const [role, setRole] = useState("");
  const [assets, setAssets] = useState();

  const [entityid, setEntityid] = useState({
    name: "",
    error: "",
  });

  const [user, setUser] = useState({
    name: "",
    error: "",
  });

  const [centralentity, setCentralentity] = useState({
    name: "",
    error: "",
  });

  const [centralentityaccount, setCentralentityaccount] = useState({
    name: "",
    error: "",
  });

  const loginservice = new LoginService();

  const history = useHistory();

  const roles = ["Central bank", "Wholesale bank", "Exchange"];

  const issuanceservice = new IssuanceService();
  const usetoken = new useToken();
  //  const consolelogger = new ConsoleLogger();

  useEffect(() => {
    var user = usetoken.getUser();
    setEntityinfo(user);
    refresh();
  }, []); //

  useEffect(() => {
    gotoproperrole();
  }, [entityinfo.role]); //

  useEffect(() => {
    if (symbol.issuetype) {
      availableformint();
    }
  }, [symbol.issuetype]); //
  const gotoproperrole = async () => {
    if (entityinfo.role == "Central bank") {
      alert("Logged in as Central bank, going to Central bank admin");
      history.push("/central-bank/cbdc-admin");
    }
  };

  const refresh = async () => {
    const tokendata = await loginservice.getlatestuser();
    if (tokendata.token) {
      usetoken.saveToken(tokendata);
      setEntityinfo(tokendata.user);
    }
    getcentralhealth();
  };

  const getcentralhealth = async () => {
    var health = await loginservice.getcentralhealth();
    console.log(health);

    if (health.entityid) {
      setEntityid({ ...entityid, name: health.entityid });
    } else {
      setEntityid({ ...entityid, error: "Failed to load entity" });
      return;
    }

    if (health.user.email) {
      setUser({ ...user, name: health.user.email });
    } else {
      setUser({ ...user, error: "Failed to load user" });
      return;
    }

    if (health.centralentity.entityname) {
      setCentralentity({
        ...centralentity,
        name: health.centralentity.entityname,
      });
    } else {
      setCentralentity({ ...centralentity, error: "Failed to create entity" });
      return;
    }

    if (health.centralentityaccount.accountnumber) {
      setCentralentityaccount({
        ...centralentityaccount,
        name: health.centralentityaccount.accountnumber,
      });
    } else {
      setCentralentityaccount({
        ...centralentityaccount,
        error: "Failed to create entity account",
      });
      return;
    }
  };

  return (
    <div className="col-12 ">
      <div className="card p-fluid">
        <h5 className="text-3xl text-center pb-3">WHOLESALE BANK HEALTH</h5>

        <div className="field text-2xl">
          <label htmlFor="entiityid">
            Entity id: <span className="text-cyan-200">{entityid.name}</span>
          </label>
          <span className="text-pink-500">{entityid.error}</span>
        </div>

        <div className="field text-2xl">
          <label htmlFor="user">
            User: <span className="text-cyan-200">{user.name}</span>
          </label>
          <span className="text-pink-500">{user.error}</span>
        </div>

        <div className="field text-2xl">
          <label htmlFor="centralentityaccount">
            Centralentityaccount:{" "}
            <span className="text-cyan-200">{centralentityaccount.name}</span>
          </label>
          <span className="text-pink-500">{centralentityaccount.error}</span>
        </div>

        <div className="field text-2xl">
          <label htmlFor="centralentity">
            Centralentity:{" "}
            <span className="text-cyan-200"> {centralentity.name}</span>
          </label>
          <span className="text-pink-500">{centralentity.error}</span>
        </div>

        <div className="flex  align-items-center  justify-content-between">
          {/* <label> */}
          <Button
            label="Refresh"
            onClick={() => refresh()}
            className=" m-3 text-2xl"
          />

          {/* </label> */}
        </div>
      </div>
    </div>
  );
}
