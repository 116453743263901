import React, { useEffect, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';

import { Dropdown } from 'primereact/dropdown';
import { Route, useHistory } from 'react-router-dom';

import { InputNumber } from 'primereact/inputnumber';
import { useToken } from '../App/useToken';
import { LoginService } from '../devlogin/LoginService';

import { IssuanceService } from '../CBtabmenu/CBHome/IssuanceService';

import { InputText } from 'primereact/inputtext';

export default function Createpairname({ setToken }) {
	const Entityinfo = {
		email: '',
		username: '',
		urlname: '',
		role: '',
		organization: '',
		accountid: '',
		entityaccountnumber: '',
		centralaccountnumber: '',
		entityemail: '',
		entityname: '',
		systemid: '',
		issuerid: '',
		funderid: '',
		entityid: '',
		marker: '',
	};

	const Sendinfo = {
		systemid: '',
		toaccountnumber: '',
		symbol: '',
		amount: '',
	};

	const [entityinfo, setEntityinfo] = useState(Entityinfo);

	const [error, setError] = useState('');
	const [selectedpair, setSelectedpair] = useState({});
	const [pairname, setPairname] = useState('');
	const [firstissuetype, setFirstissuetype] = useState('');
	const [secondissuetype, setSecondissuetype] = useState('');
	const [baseprice, setBaseprice] = useState(1.1);
	const [fraction, setFraction] = useState(1);
	const [assets, setAssets] = useState();
	const [pairs, setPairs] = useState([]);

	const loginservice = new LoginService();

	const history = useHistory();
	const issuanceservice = new IssuanceService();
	const usetoken = new useToken();

	useEffect(() => {
		var user = usetoken.getUser();
		setEntityinfo(user);
		var xx = async () => {
			var pairs = await issuanceservice.getpairs();
			setPairs(pairs);
		};

		xx();
	}, []); //

	useEffect(() => {
		gotoproperrole();
	}, [entityinfo.role]); //

	const gotoproperrole = async () => {
		if (entityinfo.role == 'Central bank') {
			alert('Logged in as Central bank, going to central bank admin');
			history.push('/central-bank/cbdc-admin');
		}
	};

	const getpairs = async () => {
		var pairs = await issuanceservice.getpairs();
		setPairs(pairs);
	};

	const disablepair = async () => {
		var data = {
			pairname: selectedpair.pairname,
		};
	};

	const enablepair = async () => {
		var data = {
			pairname: selectedpair.pairname,
		};
	};

	const createpair = async () => {
		try {
			var data = {
				pairname: pairname,
				firstissuetype: firstissuetype,
				secondissuetype: secondissuetype,
				baseprice: baseprice,
			};
			const tokendata = await loginservice.createpairname(data);
			setError('Success ' + tokendata);
		} catch (err) {
			setError('Update failed ' + err);
		}
	};

	const setpairprice = async () => {
		try {
			var data = {
				pairname: selectedpair.pairname,
				baseprice: baseprice,
			};
			const tokendata = await loginservice.setpairprice(data);
			setError('Success ' + tokendata);
		} catch (err) {
			setError('Update failed ' + err);
		}
	};

	return (
		<div className="login-wrapper col-12">
			<TabView>
				<TabPanel className="text-xl my-3" header="CREATE PAIR ">
					<h1 className="text-3xl text-center uppercase my-3 pb-4">
						Create pair for atomic swap
					</h1>

					{/* <div className="mt-5"> */}
					<div className="formgrid grid">
						<div className="field col-12 lg:col-6  text-2xl ">
							<label htmlFor="pairname">
								Pairs :{' '}
								<span className="text-cyan-200">{selectedpair.pairname}</span>
							</label>
							<Dropdown
								id="pairname"
								value={selectedpair}
								optionLabel="pairname"
								options={pairs}
								onChange={(e) => setSelectedpair(e.target.value)}
								placeholder="Select a pair"
								className="p-2 font-semibold  bg-gray-100"
							/>
						</div>
						<div className=" field col-12 lg:col-6  text-2xl">
							<label htmlFor="newpairname">
								New pairname:
								<span className="text-cyan-200"> {pairname}</span>
							</label>
							<InputText
								type="text"
								id="newpairname"
								placeholder="new pairname"
								onChange={(e) => setPairname(e.target.value)}
								style={{ height: '4rem', fontSize: '1.6rem' }}
							/>
						</div>
					</div>

					<div className="formgrid grid">
						<div className="field col-12 lg:col-6 text-2xl">
							<label htmlFor="first">First symbol</label>
							<InputText
								type="text"
								id="first"
								placeholder="first"
								onChange={(e) => setFirstissuetype(e.target.value)}
								style={{ height: '4rem', fontSize: '1.6rem' }}
							/>
						</div>

						<div className="field col-12 lg:col-6  text-2xl">
							<label htmlFor="second">Second symbol</label>
							<InputText
								type="text"
								id="second"
								placeholder=" second"
								onChange={(e) => setSecondissuetype(e.target.value)}
								style={{ height: '4rem', fontSize: '1.6rem' }}
							/>
						</div>
					</div>

					<div className="formgrid grid">
						<div className="field col-12  text-2xl">
							<label>
								Set approx price :{' '}
								<span className="text-cyan-200">{baseprice} </span>{' '}
							</label>
							<InputNumber
								id="price"
								value={baseprice}
								minFractionDigits={fraction}
								maxFractionDigits={fraction}
								onChange={(e) => setBaseprice(e.value)}
								showButtons
								mode="decimal"
								min={0}
								style={{ height: '4rem', fontSize: '1.6rem' }}
							></InputNumber>
						</div>
					</div>

					<div className="field text-2xl">
						<span className="text-pink-500">{error}</span>
						<div className="grid lg:flex align-items-center lg:justify-content-between">
							<Button
								label="Create pair"
								className="col-12 lg:col m-3 text-2xl"
								onClick={() => createpair()}
							></Button>
							<Button
								label="Refresh"
								className="col-12 lg:col m-3 text-2xl"
								onClick={() => getpairs()}
							></Button>
						</div>
					</div>
				</TabPanel>
				<TabPanel className="text-xl my-3" header="ENABLE/DISABLE">
					<div className="formgrid grid">
						<div className="field col-12  text-2xl">
							<label htmlFor="selectedpair">Pairs</label>
							<Dropdown
								id="selectedpair"
								value={selectedpair}
								optionLabel="pairname"
								options={pairs}
								onChange={(e) => setSelectedpair(e.target.value)}
								placeholder="Select a pair"
								className="p-2 font-semibold  bg-gray-100"
							/>
						</div>
					</div>

					<div className="mt-4">
						<label>
							<p className="text-2xl font-medium mb-1">
								Selected pairname:
								<span className="text-cyan-200"> {selectedpair.pairname}</span>
							</p>
						</label>
					</div>

					<div className="field text-2xl">
						<div className="grid lg:flex align-items-center lg:justify-content-between">
							<Button
								label="Disable pair"
								className="col-12 lg:col m-3 text-2xl"
								onClick={() => disablepair()}
							></Button>
							<Button
								label="Enable pair"
								className="col-12 lg:col m-3 text-2xl"
								onClick={() => enablepair()}
							></Button>
							<Button
								label="Refresh"
								className="col-12 lg:col m-3 text-2xl"
								onClick={() => getpairs()}
							></Button>
						</div>
					</div>
				</TabPanel>
				<TabPanel className="text-xl my-3" header="BASE PRICE">
					<div className="formgrid grid">
						<div className="field col-12  text-2xl">
							<label>Pairs</label>
							<Dropdown
								value={selectedpair}
								optionLabel="pairname"
								options={pairs}
								onChange={(e) => setSelectedpair(e.target.value)}
								placeholder="Select a pair"
								className="p-2 font-semibold  bg-gray-100"
							/>
						</div>
					</div>
					<div className="mt-4">
						<label>
							<p className="text-2xl font-medium mb-1">
								Selected pairname:
								<span className="text-cyan-200"> {selectedpair.pairname}</span>
							</p>
						</label>
					</div>

					<div className="mt-4">
						<label>
							<p className="text-2xl font-medium mb-1">
								Current set price :{' '}
								<span className="text-cyan-200">{selectedpair.baseprice} </span>{' '}
							</p>
						</label>
					</div>

					<div className="formgrid grid">
						<div className="field col-12  text-2xl">
							<label>
								{' '}
								<p className="text-2xl font-medium mb-1">
									Set approx price :
									<span className="text-cyan-200"> {baseprice} </span>
								</p>
							</label>
							<InputNumber
								id="price"
								value={baseprice}
								minFractionDigits={fraction}
								maxFractionDigits={fraction}
								onChange={(e) => setBaseprice(e.value)}
								showButtons
								mode="decimal"
								min={0}
								style={{ height: '4rem', fontSize: '2.0rem' }}
							></InputNumber>
						</div>
					</div>

					<div className="flex  align-items-center  justify-content-between">
						{/* <label> */}
						<Button
							label="Set approx price"
							onClick={() => setpairprice()}
							className=" m-3 text-2xl"
						/>

						{/* </label> */}
					</div>
				</TabPanel>
			</TabView>
		</div>
	);
}
