// import { useState } from 'react';

class useToken {
	//  const [token, setToken] = useState(this.getToken());

	testnewlogin = (user) => {
		if (user === null) return false; // don't do anything

		const readstoredlogin = localStorage.getItem('newlogin');
		const oldlogin = JSON.parse(readstoredlogin);

		if (oldlogin === null && user != null) {
			localStorage.setItem('newlogin', JSON.stringify(user));
			return true;
		}

		if (user.email === oldlogin.email) {
			return false;
		} else {
			localStorage.setItem('newlogin', JSON.stringify(user));
			return true;
		}
	};

	getToken = () => {
		const tokenString = localStorage.getItem('token');
		const userToken = JSON.parse(tokenString);
		return userToken?.token;
	};

	getUser = () => {
		const tokenString = localStorage.getItem('token');
		const userToken = JSON.parse(tokenString);
		return userToken?.user;
	};

	saveToken = (userToken) => {
		localStorage.setItem('token', JSON.stringify(userToken));

		//setToken(userToken.token);
	};
}

export { useToken };
