import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import '../../../../components/dropdown.css';
// select asset to transfer to another wholesale bank
const WBOFxSelecFromAcc = ({ data, setData }) => {
	const dropdownValues = [
		{ label: 'Operation Account' },
		{ label: 'Trader Account' },
	];

	return (
		<div className="grid p-fluid">
			<div className="col-12 text-center">
				<div className="text-center text-xl">
					<p className="text-center text-2xl">Select Account</p>
					<Dropdown
						value={data.fromaccount}
						onChange={(e) => setData({ ...data, fromaccount: e.target.value })}
						options={dropdownValues}
						optionLabel="label"
						placeholder="select"
						className="p-2 font-semibold  bg-gray-100"
					/>
				</div>
			</div>
		</div>
	);
};

export default WBOFxSelecFromAcc;
