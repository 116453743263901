import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";

import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { useToken } from "../../../App/useToken";
import { LoginService } from "../../../devlogin/LoginService";
import { IssuanceService } from "../IssuanceService";
import { Dialog } from "primereact/dialog";
import { Card } from "primereact/card";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Route, useHistory } from "react-router-dom";

const Wholesalebanks = () => {
  const Entityinfo = {
    email: "",
    username: "",
    urlname: "",
    role: "",
    organization: "",
    accountid: "",
    entityaccountnumber: "",
    centralaccountnumber: "",
    entityemail: "",
    entityname: "",
    systemid: "",
    issuerid: "",
    funderid: "",
    entityid: "",
    marker: "",
  };

  const [entityinfo, setEntityinfo] = useState(Entityinfo);

  const [displayBasic, setDisplayBasic] = useState();
  const [wholesalebanks, setWholesalebanks] = useState([]);
  const [wholesalebank, setWholesalebank] = useState({});
  const [error, setError] = useState("");
  // assets requested from wholesale bank with details
  const [data, setData] = useState({
    approved: true,
    requested: true,
    denied: true,
    party: "O-WHOLESALEONE, L=LONDON, C=GB ",
    cbdc_name: "Digital_pound",
    request_id: 1000,
    amount: 25000000,
    bonds: 25000000,
  });
  const header = (
    <div className="flex-column">
      <p className="text-xl">Requesting Party:{data.party}</p>
    </div>
  );
  const loginservice = new LoginService();

  const history = useHistory();
  const usetoken = new useToken();

  useEffect(() => {
    //    var user = usetoken.getUser();
    //setEntityinfo(user);
    getdata1();
  }, []); //
  useEffect(() => {
    getwholesalebanks();
  }, [entityinfo]); //
  const gotoproperrole = async () => {
    if (entityinfo.role == "Wholesale bank") {
      alert("Logged in as Wholesale bank, going to Wholesale bank admin");
      history.push("/wholesale-bank-one/cbdc-admin");
    }
  };

  const getdata1 = async () => {
    const tokendata = await loginservice.getlatestuser();
    if (tokendata.token) {
      usetoken.saveToken(tokendata);
      setEntityinfo(tokendata.user);
    }
  };

  const getwholesalebanks = async () => {
    try {
      const tokendata = await loginservice.getwholesalebanks();
      console.log(tokendata);
      if (Array.isArray(tokendata)) {
        var xx = tokendata.filter((yy) => {
          if (yy.entityid == entityinfo.entityid) return true;
        });
        setWholesalebanks(xx);
      }
    } catch (err) {
      setError("Update failed " + err);
    }
  };

  const enablewb = async () => {
    const tokendata = await loginservice.cbenablewb(wholesalebank);
    if (tokendata[0]) setWholesalebank(tokendata[0]);
    getwholesalebanks();
  };

  const disablewb = async () => {
    const tokendata = await loginservice.cbdisablewb(wholesalebank);
    if (tokendata[0]) setWholesalebank(tokendata[0]);
    getwholesalebanks();
  };

  return (
    <>
      <div className="card p-fluid">
        <h5 className="text-3xl text-center my-4">WHOLESALE BANKS</h5>
        <div className="field text-2xl mt-5">
          <label htmlFor="cbank">Choose wholesale bank</label>

          <Dropdown
            id="wbank"
            optionLabel="organization"
            value={wholesalebank}
            options={wholesalebanks}
            onChange={(e) => setWholesalebank(e.target.value)}
            placeholder="Select a wholesale bank"
            className="p-2 font-semibold  bg-gray-100"
          />
        </div>

        <div className="field grid">
          <div className="field text-2xl">
            <label htmlFor="enabled">
              The bank is : {wholesalebank.subenable}
            </label>
          </div>
        </div>

        <div className="field text-2xl">
          <div className="flex align-items-center jusify-content-center">
            <Button
              label="Enable"
              onClick={() => enablewb()}
              className=" m-3 text-2xl"
              disabled={wholesalebank.account !== 0}
            />
            <Button
              label="Disable"
              onClick={() => disablewb()}
              className=" m-3 text-2xl"
              disabled={wholesalebank.account !== 0}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Wholesalebanks;
