import React, { useState, useEffect } from 'react';

import { TabMenu } from 'primereact/tabmenu';

import WBOFxTransferCBDC from './FxTradeWBO/WBFxTransferCBDC';
import WBFxRequestDVP from './FxTradeWBO/WBFxRequestDVP';
import WBFxTraderAssets from './FxTradeWBO/WBFxTraderAssets';
import WBFxCbdcStates from './FxTradeWBO/WBFxCbdcStates';
import WBOpCbdcStates from './FxTradeWBO/WBOpCbdcStates';
import WBFxMAC from './FxTradeWBO/WBFxMAC';
import WBFxOperationAssets from './FxTradeWBO/WBFxOperationAssets';
import WBCbdcStates from './HomeWBO/WBCbdcStates';
import { useToken } from '../App/useToken';
import { LoginService } from '../devlogin/LoginService';

import { WB01IssuanceService } from './HomeWBO/WB01IssuanceService';

const WBOFxTrade = ({ data, setData }) => {
	const [activeone, setActiveone] = useState(0);
	const [activetwo, setActivetwo] = useState(0);
	const [activethree, setActivethree] = useState(0);
	const [assets, setAssets] = useState([]);
	const [accountowners, setAccountowners] = useState([]);
	const [transactionhappened, setTransactionhappened] = useState(false);
	const [transacted, setTransacted] = useState({
		operation: false,
		trader: false,
	});

	const issuanceservice = new WB01IssuanceService();
	const loginservice = new LoginService();
	const usetoken = new useToken();

	const getlatestdata = async () => {
		const tokendata = await loginservice.getlatestuser();
		if (tokendata.token) {
			usetoken.saveToken(tokendata);
		}
	};

	useEffect(() => {
		getlatestdata();
		issuanceservice.getaccountowners().then((data) => {
			var yy = data.list.filter((xx) => {
				if (!(xx.centralenable === 'disabled' || xx.subenable === 'disabled'))
					return true;
				else return false;
			});
			setAccountowners(yy);
		});
	}, []);

	// Center operate
	const DisplayOne = () => {
		if (activeone === 0) {
			return (
				<WBOFxTransferCBDC
					transacted={transacted}
					setTransacted={setTransacted}
				/>
			);
		} else if (activeone === 1) {
			return <WBFxRequestDVP data={data} setData={setData} />;
		}
	};

	// Right side display
	const DisplayTwo = () => {
		if (activetwo === 0) {
			return (
				<WBFxOperationAssets
					assets={assets}
					transacted={transacted}
					setTransacted={setTransacted}
				/>
			);
		} else if (activetwo === 1) {
			return (
				<WBFxTraderAssets
					assets={assets}
					transacted={transacted}
					setTransacted={setTransacted}
				/>
			);
		}
	};

	// Transactions
	const DisplayThree = () => {
		if (activethree === 0) {
			// return <WBFxCbdcStates data={data} setData={setData} />;
			return (
				<WBCbdcStates
					accountowners={accountowners}
					transactionhappened={transactionhappened}
					setTransactionhappened={setTransactionhappened}
				/>
			);
		} else if (activethree === 1) {
			return <WBOpCbdcStates data={data} setData={setData} />;
		}
	};

	const wizardItems = [
		{
			label: 'TRANSFER ASSETS',
			icon: 'pi pi-fw text-2xl text-yellow-500 pi-arrow-right-arrow-left',
		},
	];

	const dataItems = [
		{
			label: 'OPERATION ASSETS',
			icon: 'pi pi-fw text-2xl text-yellow-500 pi-chart-pie',
		},
		{
			label: 'TRADER ASSETS',
			icon: 'pi pi-fw text-2xl text-yellow-500 pi-wallet',
		},
	];

	const dataStates = [
		{
			label: 'OPERATION TRANSACTIONS',
			icon: 'pi text-2xl text-yellow-500 pi-fw pi-sort-alt',
		},
		{
			label: 'TRADER TRANSACTIONS',
			icon: 'pi text-2xl text-yellow-500 pi-fw pi-sort-alt',
		},
	];

	return (
		<>
			<div className="grid p-fluid p-5">
				<div className="row-12 col-12 lg:col-6 p-5">
					<div className="  card card-w-title border-1 border-100 h-full  ">
						<TabMenu
							model={wizardItems}
							activeIndex={activeone}
							onTabChange={(e) => setActiveone(e.index)}
							style={{ fontSize: '1.2rem' }}
						/>
						{DisplayOne()}
					</div>
				</div>
				<div className="row-12 col-12 lg:col-6 p-5">
					<div className=" card card-w-title border-1 border-100 ">
						<TabMenu
							model={dataItems}
							activeIndex={activetwo}
							onTabChange={(e) => setActivetwo(e.index)}
							style={{ fontSize: '1.2rem' }}
						/>
						{DisplayTwo()}
					</div>
				</div>
			</div>
			<div className="row-12  col-12  p-6">
				<div className="card card-w-title border-1 border-100 ">
					<TabMenu
						model={dataStates}
						id={dataStates.id}
						activeIndex={activethree}
						onTabChange={(e) => setActivethree(e.index)}
						style={{ fontSize: '1.2rem' }}
					/>

					{DisplayThree()}
				</div>
			</div>
		</>
	);
};

export default WBOFxTrade;
