import React, { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';
import { RotatingLines } from 'react-loader-spinner';
import { Satspertoken } from '../../App/Satspertoken';

import { WB01IssuanceService } from '../HomeWBO/WB01IssuanceService';

const WBOTreaCBDCAssets = ({ transactionhappened }) => {
	const [loading, setLoading] = useState(1);

	const [data, setData] = useState();

	const [amount, setAmount] = useState([]);
	const [issuetype, setIssuetype] = useState([]);
	const [accountnumber, setAccountnumber] = useState('');

	var issuanceservice = new WB01IssuanceService();
	var satspertoken = new Satspertoken();

	useEffect(() => {
		setLoading(1);
		if (accountnumber !== '') {
			issuanceservice
				.getbalancefromaccountnumber(accountnumber)
				.then((res) => {});
		}
	}, [accountnumber]);

	useEffect(() => {
		if (amount.length > 0) {
			setLoading(2);
		} else {
			setLoading(1);
		}
	}, [amount]);

	useEffect(() => {
		//fetch data from api
		const fetchData = async () => {
			const dataset1 = [];
			const dataset2 = [];
			/*
      await fetch(url)
        .then((data) => {
          console.log("api data", data);
          const res = data.json();
          return res;
        })
	    */

			issuanceservice
				.getsubscribebankbalance()
				.then((res) => {
					setAccountnumber(res.accountnumber);
					console.log('ress', res.balance);
					for (const val of res.balance) {
						dataset1.push(satspertoken.wrap(val.satspertoken, val.amount));
						dataset2.push(val.issuetype);
					}
					setData({
						labels: dataset2,
						datasets: [
							{
								data: dataset1,
								backgroundColor: [
									'#FFD700',
									'#A38A00',
									'#FFDE2E',
									'#D1B000',
									'#A38A00',
								],
								hoverBackgroundColor: ['#FFE55C'],
							},
						],
					});
					setAmount(dataset1);
					setIssuetype(dataset2);
					console.log('arrData', dataset1, dataset2);
				})
				.catch((e) => {
					console.log('error', e);
				});
		};
		fetchData();
	}, [transactionhappened]);
	const [lightOptions] = useState({
		indexAxis: 'x',
		elements: {
			doughnut: {
				borderWidth: 2,
			},
		},
		plugins: {
			legend: {
				position: 'right',
				labels: {
					color: '#eee',
				},
			},
			title: {
				display: true,
				text: '',
				color: '#eee',
			},
		},
	});

	return (
		<>
			<div className="col-12 ">
				<div className="grid p-fluid md:justify-content-around">
					<div className="col-12 md:col-4 lg:col-3  ">
						<div className="card border-1 border-300 bg-gray-800  mt-3 card-w-title">
							<p className="border-bottom-1 pb-2 text-3xl">CBDC</p>
							{issuetype.map((cdata, index) => (
								<div className="list-disc" key={index}>
									<li className="text-2xl pb-2">{cdata} </li>
								</div>
							))}
						</div>
					</div>
					<div className="col-12 md:col-4 lg:col-3 ">
						<div className="card border-1 border-300 bg-gray-800  mt-3  card-w-title">
							<p className="border-bottom-1 pb-2 text-3xl">Total assets</p>
							{amount.map((cdata, index) => (
								<div className="list-disc" key={index}>
									<li className="text-2xl pb-2">
										{cdata.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
									</li>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>

			<div className="  flex flex-column align-items-center ">
				{loading === 1 ? (
					<div>
						<RotatingLines
							strokeColor="grey"
							strokeWidth="5"
							animationDuration="0.75"
							width="40"
							visible={true}
						/>
					</div>
				) : (
					''
				)}

				<Chart
					type="doughnut"
					data={data}
					options={lightOptions}
					style={{
						position: 'relative',
						width: '60%',
						justifyContent: 'center',
					}}
					className="w-9 md:w-7 lg:w-5 xl:w-4"
				/>
			</div>
		</>
	);
};

export default WBOTreaCBDCAssets;
