import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { IssuanceServiceRI } from "./IssuanceServiceRI";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputNumber } from "primereact/inputnumber";

const Requestissuance = () => {
  const [displayBasic, setDisplayBasic] = useState(false);
  const [totalvalue, setTotalvalue] = useState(0);
  const [volume, setVolume] = useState(0);
  const [wholesalebanks, setWholesalebanks] = useState([]);
  const [assets, setAssets] = useState([]);
  const [wholesalebank, setWholesalebank] = useState({});
  const [organization, setOrganization] = useState("");
  const [symbol, setSymbol] = useState({});
  const [message, setMessage] = useState("");

  const issuanceService = new IssuanceServiceRI();

  const clickAtomBuy = () => {};

  useEffect(() => {
    issuanceService.getentityassets().then((data) => {
      var xx = data.map(function (value) {
        return {
          label: value.issuetype,
          id: value.id,
          entityid: value.entityid,
          issueaccountnumber: value.issueaccountnumber,
          assetid: value.assetid,
          satspertoken: value.satspertoken,
          issuetype: value.issuetype,
          amount: value.amount,
        };
      });

      setAssets(xx);
    });
  }, []);

  const requestissuance = async () => {
    console.log("sending");
    var data = {
      message: message,
      issuetype: symbol.issuetype,
      assetid: symbol.assetid,
      amount: volume,
    };
    var ret = await issuanceService.requestissuance(data);
    console.log(ret);
  };

  return (
    <div className="card border-2 border-green-400 grid p-fluid">
      <div className="col-12 text-center">
        <div className="flex justify-space-between gap-4 text-center card border-1 border-100 bg-gray-800 text-xl w-full">
          <label>
            <p className="text-2xl">Choose asset to request </p>
            <Dropdown
              optionLabel="issuetype"
              value={symbol}
              options={assets}
              onChange={(e) => {
                setSymbol(e.target.value);
              }}
              placeholder="Select asset to request"
              className="p-2 font-semibold  bg-gray-100"
            />
          </label>
        </div>
        <div className="flex justify-space-between gap-4 text-center card border-1 border-100 bg-gray-800 text-xl w-full">
          <InputNumber
            id="volume"
            value={volume}
            onChange={(e) => setVolume(e.value)}
            minFractionDigits={2}
            maxFractionDigits={2}
            showButtons
            mode="decimal"
            placeholder="volume to request"
            min={0}
            max={10000}
            style={{ height: "3rem", fontSize: "1.5rem" }}
            className="p-inputnumber"
          ></InputNumber>
        </div>
        <div className="flex justify-space-between gap-4 text-2xl text-center card border-1 border-100 bg-gray-800 text-xl w-full">
          <InputTextarea
            placeholder="Message for issuance request"
            onChange={(e) => setMessage(e.target.value)}
            rows={5}
            cols={30}
            className="text-2xl"
          />
        </div>
        <div className="p-5">
          <div className="flex align-items-center justify-content-between">
            <div className="w-6rem text-white font-bold flex align-items-center justify-content-center mr-3">
              <Dialog
                header={"xxx"}
                visible={displayBasic}
                modal
                onHide={() => setDisplayBasic(false)}
                className="w-5"
              >
                <Card
                  style={{ marginBottom: "2rem" }}
                  className="transition-colors transition-duration-500 hover:bg-gray-900 "
                >
                  <div className="flex align-items-center ">
                    <div>
                      <p className="text-3xl border-bottom-1 surface-border p-2"></p>
                      <p className="text-2xl">Volume {volume}</p>
                    </div>
                  </div>
                </Card>

                <div className="flex align-items-center justify-content-center">
                  <Button
                    type="button"
                    // icon="pi pi-minus"

                    label=" CONFIRM REDEEM"
                    onClick={() => clickAtomBuy()}
                    className="col-12 lg:col-3 text-xl  p-button-success"
                  />
                </div>
              </Dialog>
              <ToastContainer
                // position="top-right"
                // autoClose={5000}
                // hideProgressBar={false}
                // newestOnTop={false}
                // closeOnClick
                // rtl={false}
                // pauseOnFocusLoss
                // draggable
                // pauseOnHover
                // theme="colored"
                className="text-2xl"
                style={{ width: "70rem" }}
              />
              <div className="flex align-items-center justify-content-center">
                <Button
                  onClick={() => requestissuance()}
                  label="REQUEST ISSUANCE "
                  className="col-12 lg:col-3 text-xl  p-button-success"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Requestissuance;
