import React, { useEffect, useState } from 'react';
import { RotatingLines } from 'react-loader-spinner';
import { Chart } from 'primereact/chart';
import { Satspertoken } from '../../App/Satspertoken';

import { WB01IssuanceService } from './WB01IssuanceService';

const WBCBDCAssets = ({ transactionhappened }) => {
	const [loading, setLoading] = useState(1);
	const [data, setData] = useState();

	const [amount, setAmount] = useState([]);
	const [issuetype, setIssuetype] = useState([]);
	const [accountnumber, setAccountnumber] = useState('');

	useEffect(() => {
		var issuanceservice = new WB01IssuanceService();
		setLoading(1);
		if (accountnumber !== '') {
			issuanceservice
				.getbalancefromaccountnumber(accountnumber)
				.then((res) => {});
		}
	}, [accountnumber]);

	useEffect(() => {
		const issuanceservice = new WB01IssuanceService();
		const satspertoken = new Satspertoken();

		const fetchData = async () => {
			try {
				const res = await issuanceservice.getsubscribebankbalance();
				setAccountnumber(res.accountnumber);

				const dataset1 = res.balance.map((val) =>
					satspertoken.wrap(val.satspertoken, val.amount)
				);
				const dataset2 = res.balance.map((val) => val.issuetype);

				setData({
					labels: dataset2,
					datasets: [
						{
							data: dataset1,
							backgroundColor: [
								'#FFD700',
								'#A38A00',
								'#FFDE2E',
								'#D1B000',
								'#A38A00',
							],
							hoverBackgroundColor: ['#FFE55C'],
						},
					],
				});

				setAmount(dataset1);
				setIssuetype(dataset2);
				setLoading(2);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, []); // Ensure this effect runs only once
	const [lightOptions] = useState({
		indexAxis: 'x',
		elements: {
			doughnut: {
				borderWidth: 2,
			},
		},
		plugins: {
			legend: {
				position: 'bottom',
				labels: {
					color: '#eee',
				},
			},
			title: {
				display: true,
				text: '',
				color: '#eee',
			},
		},
	});

	return (
		<>
			<div className="col-12 ">
				<div className="grid p-fluid">
					<div className="col-12 md:col-6 ">
						<div className="card border-1 border-300 bg-gray-800  mt-3 card-w-title">
							<p className="border-bottom-1 pb-2 text-3xl">CBDC</p>
							{issuetype.map((cdata, index) => (
								<div className="list-disc" key={index}>
									<li className="text-2xl pb-2">{cdata} </li>
								</div>
							))}
						</div>
					</div>
					<div className="col-12 md:col-6 ">
						<div className="card border-1 border-300 bg-gray-800  mt-3 card-w-title">
							<p className="border-bottom-1 pb-2 text-3xl">Total assets</p>
							{amount.map((cdata, index) => (
								<div className="list-disc" key={index}>
									<li className="text-2xl pb-2">
										{cdata.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
									</li>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>

			<div className="  flex flex-column align-items-center ">
				{loading === 1 ? (
					<div>
						<RotatingLines
							strokeColor="grey"
							strokeWidth="5"
							animationDuration="0.75"
							width="40"
							visible={true}
						/>
					</div>
				) : (
					''
				)}

				<Chart
					type="doughnut"
					data={data}
					options={lightOptions}
					className="w-9 md:w-7"
					style={{
						position: 'relative',
						justifyContent: 'center',
					}}
				/>
			</div>
		</>
	);
};

export default WBCBDCAssets;
