import { TabMenu } from "primereact/tabmenu";
import React, { useState } from "react";
import { Route, useHistory } from "react-router-dom";

import WBOReqCCSReq from "./WBORequests/WBOReqCCSReq";
import Redemption from "./WBORequests/Redemption";
import Requestissuance from "./WBORequests/Requestissuance";

const WBORequests = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const history = useHistory();
  const wizardItems = [
    {
      label: "CBDC ISSUANCE REQUESTS",
      icon: "pi pi-fw text-2xl text-yellow-500 pi-dollar",
    },
    {
      label: "CBDC REDEMPTION REQUESTS",
      icon: "pi pi-fw text-2xl text-yellow-500 pi-sync",
    },
    /*
    {
      label: "DVP REQUESTS",
      icon: "pi pi-fw pi-arrow-right-arrow-left",
      command: () => history.push("/wholesale-bank-one/requests/dvp-requests"),
    },
    {
      label: "PULL TRANSFER REQUESTS",
      icon: "pi pi-fw pi-dollar",
      // command: () =>
      // history.push("/wholesale-bank-one/cbdc-manager/issue-cbdc"),
    },
    {
      label: "CROSS CHAIN SWAP REQUESTS",
      icon: "pi pi-fw pi-times",
      command: () =>
        history.push("/wholesale-bank-one/requests/cross-chain-swap-requests"),
    },
    {
      label: "MEMBER ACCESS REQUESTS",
      icon: "pi pi-fw pi-user",
      // command: () =>
      //   history.push("/wholesale-bank-one/requests/cross-chain-swap"),
    },
    */
  ];
  const DisplayItem = () => {
    if (activeIndex === 0) {
      return <Requestissuance />;
    } else if (activeIndex === 1) {
      return <Redemption />;
    }
  };

  return (
    <div className="grid p-fluid p-5 d-flex justify-content-center">
      <div className="row-12  col-12 lg:col-6 p-5">
        <div className="card card-w-title border-1 border-100 h-full">
          {/* <h5 className="text-3xl p-4">Requests</h5> */}
          <TabMenu
            model={wizardItems}
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
            readOnly={false}
            style={{ fontSize: "1.2rem" }}
          />
          {DisplayItem()}
        </div>
      </div>
    </div>
  );
};

export default WBORequests;
