import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { RotatingLines } from 'react-loader-spinner';

// page for select asset step
const SelectAssetCBTrans = ({
	data,
	setData,
	centralbalance,
	setUpdateasset,
}) => {
	const [loading, setLoading] = useState(true);

	// Simulate API call delay (you should replace this with your actual API call)
	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 2000); // Simulate a 2-second delay
	}, []);

	const handleDropdownChange = (e) => {
		setData({ ...data, asset: e.target.value });
		setUpdateasset(e.target.value);
	};
	return (
		<div className="grid p-fluid">
			<div className="col-12 text-center">
				<div className="text-center text-xl">
					<p className="text-center text-3xl">
						Select available Asset for transfer.
					</p>
					{loading ? (
						// Show a loading message or spinner while data is being fetched
						// <p>Loading...</p>
						<RotatingLines
							strokeColor="grey"
							strokeWidth="5"
							animationDuration="0.75"
							width="40"
							visible={true}
						/>
					) : (
						<Dropdown
							value={data.asset}
							onChange={handleDropdownChange}
							options={centralbalance}
							optionLabel="label"
							placeholder="Select Asset"
							className="p-2 font-semibold  bg-gray-100"
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default SelectAssetCBTrans;
