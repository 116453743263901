import React, { useState, useEffect } from 'react';
// import { ProgressSpinner } from 'primereact/progressspinner';
import { RotatingLines } from 'react-loader-spinner';
import { Chart } from 'primereact/chart';
import { IssuanceService } from '../IssuanceService';
import { Satspertoken } from '../../../App/Satspertoken';

// import configData from '../../../App/Config.json';

const CBDCAssets = ({
	allassets,
	data1,
	setData1,
	user,
	token,
	transactionhappened,
	balances,
	accountowners,
}) => {
	// initail value for chart data

	const [loading, setLoading] = useState(1);
	const [data, setData] = useState();
	const [amount, setAmount] = useState([]);
	const [issuetype, setIssuetype] = useState([]);
	// eslint-disable-next-line
	const [accountnumber, setAccountnumber] = useState('');

	useEffect(() => {
		const issuanceservice = new IssuanceService();
		setLoading(1);
		issuanceservice.getcentralaccount().then((res) => {
			setAccountnumber(res.accountnumber);
		});
	}, []);
	useEffect(() => {
		if (amount.length > 0) {
			setLoading(2);
		} else {
			setLoading(1);
		}
	}, [amount]);

	useEffect(() => {
		let active = false;
		const satspertoken = new Satspertoken();
		//fetch data from api
		const fetchData = async () => {
			const dataset1 = [];
			const dataset2 = [];

			if (balances.balances) {
				balances.balances.forEach((xx) => {
					if (xx.address === user.centraladdress) {
						if (xx.tokens) {
							xx.tokens.forEach((yy) => {
								dataset1.push(
									satspertoken.satisfy(allassets, yy.symbol, yy.balance)
								);
								dataset2.push(yy.symbol);
							});
						}
					}
				});
			}
			if (!active) {
				setData({
					labels: dataset2,
					datasets: [
						{
							data: dataset1,
							backgroundColor: ['#1569BB', '#00C6AE', '#36A2EB', '#6B7280'],
							hoverBackgroundColor: [
								'#1a85ed',
								'#00f7d9',
								'#4bb3fa',
								'#7f8694',
							],
						},
					],
				});
				setAmount(dataset1);
				setIssuetype(dataset2);
				console.log('arrData', dataset1, dataset2);
			}
		};

		fetchData();
		return () => {
			active = true;
		};
	}, [balances, allassets, user.centraladdress, transactionhappened]);

	const [lightOptions] = useState({
		indexAxis: 'x',
		elements: {
			doughnut: {
				borderWidth: 2,
			},
		},
		plugins: {
			legend: {
				position: 'bottom',
				labels: {
					color: '#eee',
				},
			},
			title: {
				display: true,
				text: '',
				color: '#eee',
			},
		},
	});

	return (
		<>
			<div className="col-12 ">
				<div className="grid p-fluid">
					<div className="col-12 md:col-6 ">
						<div className="card border-1 border-300 bg-gray-800  mt-3 card-w-title">
							<p className="border-bottom-1 pb-2 text-3xl">CBDC</p>
							{issuetype.map((cdata, index) => (
								<div className="list-disc" key={index}>
									<li className="text-2xl pb-2" key={index}>
										{cdata}{' '}
									</li>
								</div>
							))}
						</div>
					</div>
					<div className="col-12 md:col-6 ">
						<div className="card border-1 border-300 bg-gray-800  mt-3  card-w-title">
							<p className="border-bottom-1 pb-2 text-3xl">Total assets</p>
							{amount.map((cdata, index) => (
								<div className="list-disc" key={index}>
									<li className="text-2xl pb-2" key={index}>
										{cdata.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
									</li>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
			<div className="  flex flex-column align-items-center ">
				{loading === 1 ? (
					<div>
						<RotatingLines
							strokeColor="grey"
							strokeWidth="5"
							animationDuration="0.75"
							width="40"
							visible={true}
						/>
					</div>
				) : (
					''
				)}

				<Chart
					type="doughnut"
					data={data}
					options={lightOptions}
					className="w-9 md:w-7"
					style={{
						position: 'relative',
						// width: "60%",
						justifyContent: 'center',
					}}
				/>
			</div>
		</>
	);
};

export default CBDCAssets;
