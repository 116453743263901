import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { RotatingLines } from 'react-loader-spinner';

// select asset to transfer to another wholesale bank
const WBOTSelectAsset = ({
	data,
	setData,
	subscriberbalance,
	setUpdateasset,
}) => {
	const [loading, setLoading] = useState(true);

	// Simulate API call delay (you should replace this with your actual API call)
	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 6000); // Simulate a 2-second delay
	}, []);

	const handleDropdownChange = (e) => {
		setData({ ...data, asset: e.target.value });
		setUpdateasset(e.target.value);
	};

	return (
		<div className="grid p-fluid">
			<div className="col-12 text-center">
				<div className="text-center text-xl">
					<p className="text-center text-2xl">
						Select available asset for transfer.
					</p>
					{loading ? (
						// Show a loading message or spinner while data is being fetched
						<RotatingLines
							strokeColor="grey"
							strokeWidth="5"
							animationDuration="0.75"
							width="40"
							visible={true}
						/>
					) : (
						// <p>Loading...</p>
						// Show the dropdown when data is fetched
						<Dropdown
							value={data.asset}
							onChange={handleDropdownChange}
							options={subscriberbalance}
							optionLabel="label"
							placeholder="Select"
							className="p-2 font-semibold bg-gray-100"
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default WBOTSelectAsset;
