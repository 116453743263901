import React, { useState, useEffect } from 'react';
import { NavLink, Route, useHistory } from 'react-router-dom';
import { Menubar } from 'primereact/menubar';
import { BsGlobe2 } from 'react-icons/bs';
import { BiLogOut } from 'react-icons/bi';
import { TabMenu } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
import { useToken } from '../../components/App/useToken';
import CBHome from '../../components/CBtabmenu/CBHome';
import Admincentral from '../Admin/Admincentral';
import CbMenbercontrols from '../../components/CBtabmenu/CbMenbercontrols';
import CBIncomingRequest from '../../components/CBtabmenu/CBIncomingRequest';
import CBTreasuryDashboard from '../../components/CBtabmenu/CBTreasuryDashboard';

import CBRefernceModal from '../../components/CBtabmenu/CBRefernceModal';
import CBVisiblity from '../../components/CBtabmenu/CBVisiblity';

const CentralBank = () => {
	const history = useHistory();
	//  if(useToken.getUser().marker)
	const usetoken = new useToken();
	const [user, setUser] = useState(usetoken.getUser());

	const Redirectnonconfigured = () => {
		if (!user || user.marker === '') {
			alert('The system is not configured');
			history.push('/');
		}
	};

	useEffect(() => {
		gotoproperrole();
	}, [user.role]); //

	const gotoproperrole = async () => {
		if (user.role === 'Wholesale bank') {
			alert('Logged in as Wholesale bank, going to Wholesale bank ');
			history.push('/wholesale-bank-one');
		}
	};

	const wizardItems = [
		{
			label: 'HOME',
			icon: 'pi text-2xl text-blue-500 pi-fw pi-th-large',
			command: () => history.push('/central-bank'),
		},
		{
			label: 'CBDC ADMIN',
			icon: 'pi text-2xl text-blue-500 pi-fw pi-shield',
			command: () => history.push('/central-bank/cbdc-admin'),
		},
		{
			label: 'CENTRAL BANK CONTROL ',
			icon: 'pi text-3xl text-blue-500 pi-fw pi-user-edit',
			command: () => history.push('/central-bank/centralbank-controls'),
		},

		{
			label: 'TREASURY DASHBOARD',
			icon: 'pi text-2xl text-blue-500  pi-fw pi-chart-pie',
			command: () => history.push('/central-bank/treasury-dashboard'),
		},
	];

	Redirectnonconfigured();

	const logout = (
		<span className="flex align-items-center justify-content-center">
			<BiLogOut className="text-3xl mr-2  text-red-500" />
			LOGOUT
		</span>
	);

	const handleLogout = () => {
		localStorage.clear();
		window.location.href = '/cb-login';
	};

	return (
		<div>
			<Menubar
				start={
					<img
						src={'https://intrasettle.com/static/img/intrasettle_white.svg'}
						alt="logo"
						style={{ width: '12rem' }}
					/>
				}
				end={
					<div className="flex align-items-center justify-content-center">
						<BsGlobe2 className="text-lg md:text-3xl mr-2 md:mr-3 text-blue-500" />
						<span className="text-md sm:text-2xl md:text-3xl text-white mr-2 md:mr-3">
							The {user.organization}
						</span>
						<Button
							label={logout}
							onClick={() => handleLogout()}
							className="col m-3 text-md sm:text-lg md:text-xl p-button-danger"
						/>
					</div>
				}
				className="pt-4 pb-4 layout-topbar"
			/>
			<div className=" col-12  justify-content-around pt-8 px-7">
				<TabMenu
					model={wizardItems}
					style={{ fontSize: '1.3rem' }}
					className="pt-4 pb-1 card text-xl"
				/>
			</div>
			<Route exact path={'/central-bank'} component={CBHome} />
			<Route path={'/central-bank/cbdc-admin'} component={Admincentral} />
			<Route
				path={'/central-bank/member-controls'}
				component={CbMenbercontrols}
			/>
			<Route
				path={'/central-bank/centralbank-controls'}
				component={CBIncomingRequest}
			/>

			<Route
				path={'/central-bank/treasury-dashboard'}
				component={CBTreasuryDashboard}
			/>
			<Route
				path={'/central-bank/reference-modals'}
				component={CBRefernceModal}
			/>
			<Route
				path={'/central-bank/visibility-reissuance'}
				component={CBVisiblity}
			/>
		</div>
	);
};

export default CentralBank;
