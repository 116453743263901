import { TabMenu } from 'primereact/tabmenu';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useState } from 'react';
import { Route, useHistory } from 'react-router-dom';

import CBTDMoneySupply from './CBHome/CBTreasuryDashboard/CBTDMoneySupply';

const CBTreasuryDashboard = () => {
	const [activeIndex, setActiveIndex] = useState(0);
	const history = useHistory();
	const wizardItems = [
		{
			label: 'MONEY SUPPLY',
			icon: 'pi text-xl text-blue-500 pi-fw pi-gift',
		},
	];
	//<CBDCAssets allassets={allassets} data={data} setData={setData} user={user}  transactionhappened={transactionhappened}  balances={balances}  accountowners={accountowners}
	const DisplayItem = () => {
		if (activeIndex === 0) {
			return <CBTDMoneySupply />;
		}
	};

	return (
		<div className="grid p-fluid p-5 d-flex justify-content-center">
			<div className="row-12  col-12 lg:col-9 p-5">
				<div className="card card-w-title border-1 border-100 h-full">
					{/* <h5 className="text-3xl p-5">Dashboard </h5> */}
					<div>
						<TabMenu
							model={wizardItems}
							activeIndex={activeIndex}
							onTabChange={(e) => setActiveIndex(e.index)}
							readOnly={false}
							style={{ fontSize: '1.3rem' }}
						/>
						{DisplayItem()}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CBTreasuryDashboard;
